<template>
  <div class="body text-center">
    <div id="result-fail" class="d-flex flex-column align-items-center">
      <img
        src="@/assets/images/success.png"
        width="80"
        v-if="data.status == 'success'"
      />
      <img
        src="@/assets/images/unsuccess.png"
        width="80"
        v-else-if="data.status == 'failed'"
      />
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91.529"
        height="91.529"
        viewBox="0 0 91.529 91.529"
      >
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="45.765" cy="45.765" r="45.765" fill="red" />
        <path
          id="Path_1204"
          data-name="Path 1204"
          d="M51.612,15,32.141,41.479"
          transform="translate(3.839 18.079)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="6"
        />
        <path
          id="Path_8812"
          data-name="Path 8812"
          d="M32.141,15,51.612,41.479"
          transform="translate(3.838 18.08)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="6"
        />
      </svg> -->

      <div v-html="data.msg" style="margin-top:30px;"></div>
    </div>
    <br />
    <br />
    <br />
    <b-button
      id="submit-finish"
      variant="white"
      type="button"
      @click="closeWindow"
      >เสร็จสิ้น</b-button
    >
  </div>
</template>

<script>
export default {
  name: "page-result",
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    closeWindow() {
      this.$liff.closeWindow();
    }
  }
};
</script>

<style scoped>
.title-otp {
  font-size: 14px;
}
.not-received-otp {
  font-size: 11px;
  color: #afafaf;
}
</style>
